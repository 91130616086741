import { useEffect, useState } from 'react';
import { ITags } from '../../utils/model/TagInput/ITags.model';
import { IOptions } from '../../utils/model/SelectInput/IOptions.model';
import { IconButton, SnackbarCloseReason } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getRegions } from '../../services/regions/regions.service';
import { getSkills } from '../../services/skills/skills.service';
import { getCities } from '../../services/cities/cities.service';
import { RegistrationRequest } from '../../services/registration/model/registrationRequest.model';
import { registerContactForm } from '../../services/registration/registration.service';
import { HttpStatusCode } from 'axios';
import useScreen from '../useScreen/useScreen';

interface IuseModalRegistrationFormProps {
  setOpen(value: boolean): void;
}

const useModalRegistrationForm = ({
  setOpen
}: IuseModalRegistrationFormProps) => {
  const { isSmallScreen } = useScreen();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('+56 ');
  const [email, setEmail] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [skills, setSkills] = useState<ITags[]>([]);
  const [regions, setRegions] = useState<IOptions[]>([]);
  const [cities, setCities] = useState<IOptions[]>([]);
  const [skillsOptions, setSkillsOptions] = useState<ITags[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  useEffect(() => {
    const fetchRegions = async () => {
      const fetchedRegions = await getRegions();
      setRegions(fetchedRegions);
    };

    const fetchSkills = async () => {
      const fetchedSkills = await getSkills();
      setSkillsOptions(fetchedSkills);
    };

    fetchRegions();
    fetchSkills();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      const fetchedCities = await getCities(Number(region));
      setCities(fetchedCities);
    };

    fetchCities();
  }, [region]);

  useEffect(() => {
    const isFormValid =
      name.trim() !== '' &&
      phone.trim() !== '+56' &&
      email.trim() !== '' &&
      region.trim() !== '' &&
      city.trim() !== '' &&
      skills.length > 0 &&
      isChecked &&
      !phoneError &&
      !emailError;
      console.log("🚀 ~ useEffect ~ phoneError:", phoneError)
      console.log("🚀 ~ useEffect ~ emailError:", emailError)


      console.log("🚀 ~ useEffect ~ isFormValid:", isFormValid)

    setIsButtonDisabled(!isFormValid);
  }, [name, phone, email, region, city, skills, isChecked, phoneError, emailError]);

  const handleClearForm = () => {
    setName('');
    setPhone('+56 ');
    setEmail('');
    setRegion('');
    setCity('');
    setIsChecked(false);
    setSkills([]);
  };

  const handleOpenSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    handleClearForm();
  };

  const handleSubmit = async (type: string) => {
    setIsLoading(true);
    handleCloseModal();

    const formattedSkills = `\\"${skills
      .map((skill) => skill.name)
      .join(', ')}\\"`;

    // eslint-disable-next-line
    const data = `{\"type\":\"${type}\",\"nombre_completo\":\"${name}\",\"numero_whatsapp\":\"${phone}\",\"correo_electronico\":\"${email}\",\"region\":\"${region}\",\"ciudad\":\"${city}\",\"profesion_oficio_habilidad\":\"${formattedSkills}\"}`;
    const payload: RegistrationRequest = {
      respuesta: data,
    };

    const response = await registerContactForm(payload);

    setTimeout(() => {
      setIsLoading(false);
      if (response === HttpStatusCode.Created) {
        handleOpenSnack();
      } else {
        console.log(response);
      }
    }, 1500);
  };

  const snackAction = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseSnack}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return {
    isSmallScreen,
    isLoading,
    name,
    phone,
    email,
    region,
    city,
    isChecked,
    skills,
    regions,
    cities,
    skillsOptions,
    isButtonDisabled,
    openSnack,
    snackAction,
    setName,
    setPhone,
    setEmail,
    setRegion,
    setCity,
    setIsChecked,
    setSkills,
    setPhoneError,
    setEmailError,
    handleSubmit,
    handleCloseModal,
    handleCloseSnack,
  };
};

export default useModalRegistrationForm;
