import axios from 'axios';
import { CityModel } from './model/city.model';
import { mapCitiesToOptions } from './mapper/cities.mapper';

export const getCities = async (regionId: number) => {
  //const url = `${process.env.REACT_APP_API}${process.env.REACT_APP_API_REGIONS_PATH}`
  try {
    const url =
      `https://iwiespace-qa-580d5dfa96b9.herokuapp.com/region/${regionId}/ciudades`;

    const response = await axios.get<CityModel[]>(url);

    return mapCitiesToOptions(response.data);
  } catch (error) {
    console.error('Error fetching cities:', error);
    return [];
  }
};
