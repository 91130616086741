import axios from 'axios';
import { RegionModel } from './model/region.model';
import { mapRegionsToOptions } from './mapper/regions.mapper';

export const getRegions = async () => {
  //const url = `${process.env.REACT_APP_API}${process.env.REACT_APP_API_REGIONS_PATH}`
  try {
    const url =
      'https://iwiespace-qa-580d5dfa96b9.herokuapp.com/pais/1/regiones';

    const response = await axios.get<RegionModel[]>(url);

    return mapRegionsToOptions(response.data);
  } catch (error) {
    console.error('Error fetching regions:', error);
    return [];
  }
};
