import axios from 'axios';
import { RegistrationRequest } from './model/registrationRequest.model';
import { RegistrationResponse } from './model/registrationResponse.model';

export const registerContactForm = async (payload: RegistrationRequest) => {
  try {
    const url = `https://iwiespace-prod-c57943138a3a.herokuapp.com/formulario-contacto`;

    const response = await axios.post<RegistrationResponse>(url, payload, {
      headers: {
        adminToken:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJUb2tlbiBTb2NjZXJhY2UifQ.7QNmFTUhecvsYHVatG0-s4iSKeAKAE2a4LM9_rot7SE', // Reemplaza 'your-admin-token-value' con el valor real de tu token
        uid: 'goworq_01',
      },
    });

    return response.status;
  } catch (error) {
    console.error('Error fetching cities:', error);
    return [];
  }
};
